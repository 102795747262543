import { navigate } from "gatsby"
import React from "react"

type PropTypes = {
  currentSlide: number
}

const slidesContent = [
  <div key={0} className="slide">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">Contul tău Gazmir:</div>
      <div className="slideList colorWhite">
        <div className="slColumn">
          <div>Plăți online</div>
          <div>Sold la zi</div>
          <div>Istoric plăți</div>
        </div>
        <div className="slColumn ">
          <div>Istoric consum</div>
          <div>Autocitire online</div>
          <div>Factură electronică</div>
        </div>
      </div>
      <div
        role="button"
        className="sliderButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contulMeu#newSignUpForm")}
        onTouchStart={() => navigate("/contulMeu#newSignUpForm")}
      >
        Creează-ți cont!
      </div>
    </div>
  </div>,
  <div key={1} className="slide" data-state="next">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">
        Mii de clienți industriali și casnici
      </div>
      <div className="slideSubTitle colorWhite">
        Gazmir este pe piața de furnizare de gaze naturale din 2007, în
        Miroslava. Am evoluat în timp, iar astăzi ne mândrim cu faptul ca suntem
        în top 10 furnizori din Romania. <br /> <br />
        Vrem să arătăm că o companie 100% românească poate reuși în această
        industrie prin muncă, sinceritate și flexibilitate. Ne străduim să avem
        oferte corecte și să fim transparenți pentru clienții noștri.
      </div>
      <div
        role="button"
        className="sliderButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contact")}
        onTouchStart={() => navigate("/contact")}
      >
        Contactează-ne
      </div>
    </div>
  </div>,
  <div key={2} className="slide">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">Viziunea experților noștri</div>
      <div className="slideSubTitle colorWhite">
        Echipa noastră de ingineri și specialiști depune eforturi susținute
        pentru a realiza viziunea noastră de a trăi într-o țară unde costul
        energiei este corect iar accesul la aceasta este simplificat.
      </div>
      <div
        role="button"
        className="sliderButton blueButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contact")}
        onTouchStart={() => navigate("/contact")}
      >
        Contactează-ne
      </div>
    </div>
  </div>,
  <div key={3} className="slide">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">
        Construim rețele pentru distribuția gazelor naturale
      </div>
      <div className="slideSubTitle colorWhite">
        Suntem aici pentru a crește oportunitățile economice ale consumatorilor
        industriali, cât și nivelul de confort a clienților casnici. Astfel,
        dorim să aducem gazele naturale în cât mai multe zone ale României.
      </div>
      <div
        role="button"
        className="sliderButton blueButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contact")}
        onTouchStart={() => navigate("/contact")}
      >
        Contactează-ne
      </div>
    </div>
  </div>,
  <div key={4} className="slide">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">
        Clienții noștri, partenerii noștri
      </div>
      <div className="slideSubTitle colorWhite">
        Suntem alături de clienții noștri industriali și casnici pe toată
        perioada contractuală. Astfel, asigurăm servicii calitative, rapide și
        corecte pentru un parteneriat de lungă durată.
      </div>
      <div
        role="button"
        className="sliderButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contact")}
        onTouchStart={() => navigate("/contact")}
      >
        Contactează-ne
      </div>
    </div>
  </div>,
  <div key={5} className="slide">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">
        11 localități beneficiază de expertiza noastră
      </div>
      <div className="slideSubTitle colorWhite">
        În anul 2005 am dezvoltat prima noastră rețea de distribuție în
        Miroslava. În prezent, această călătorie a condus la gestionarea unui
        portofoliu de 11 localități, având planuri de a crește acest număr la 13
        localități până în 2025.
      </div>
      <div
        role="button"
        className="sliderButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contact")}
        onTouchStart={() => navigate("/contact")}
      >
        Află mai multe
      </div>
    </div>
  </div>,
  <div key={6} className="slide">
    <div className="slideContentV2">
      <div className="slideTitle colorWhite">Serviciile noastre:</div>
      <div className="slideSubTitle colorWhite">
        <ul>
          <li>Avize de amplasament.</li>
          <li>Proiectare PDSB, PDIB și DTAC.</li>
          <li>Studii de fezabilitate și suport tehnic.</li>
          <li>Lucrări de dezvoltare a rețelelor.</li>
          <li>Mentenanță conducte și instalații.</li>
          <li>Intervenții de urgență deschis 24/7.</li>
        </ul>
      </div>
      <div
        role="button"
        className="sliderButton buttonHover textButtonHover colorWhite"
        onClick={() => navigate("/contact")}
        onTouchStart={() => navigate("/contact")}
      >
        Contactează-ne
      </div>
    </div>
  </div>,
]

export default function Slides({ currentSlide }: PropTypes) {
  // if (currentSlide === slidesContent.length - 1) {
  //   return [slidesContent[currentSlide], slidesContent[0]]
  // } else return [slidesContent[currentSlide], slidesContent[currentSlide + 1]]
  return [
    <div key={0} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">Contul tău Gazmir:</div>
        <div className="slideList colorWhite">
          <div className="slColumn">
            <div>Plăți online</div>
            <div>Sold la zi</div>
            <div>Istoric plăți</div>
          </div>
          <div className="slColumn ">
            <div>Istoric consum</div>
            <div>Autocitire online</div>
            <div>Factură electronică</div>
          </div>
        </div>
        <div
          role="button"
          className="sliderButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contulMeu#newSignUpForm")}
          onTouchStart={() => navigate("/contulMeu#newSignUpForm")}
        >
          Creează-ți cont!
        </div>
      </div>
    </div>,
    <div key={1} className="slide" data-state="next">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">
          Mii de clienți industriali și casnici
        </div>
        <div className="slideSubTitle colorWhite">
          Gazmir este pe piața de furnizare de gaze naturale din 2007, în
          Miroslava. Am evoluat în timp, iar astăzi ne mândrim cu faptul ca
          suntem în top 10 furnizori din Romania. <br /> <br />
          Vrem să arătăm că o companie 100% românească poate reuși în această
          industrie prin muncă, sinceritate și flexibilitate. Ne străduim să
          avem oferte corecte și să fim transparenți pentru clienții noștri.
        </div>
        <div
          role="button"
          className="sliderButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contact")}
          onTouchStart={() => navigate("/contact")}
        >
          Contactează-ne
        </div>
      </div>
    </div>,
    <div key={2} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">Viziunea experților noștri</div>
        <div className="slideSubTitle colorWhite">
          Echipa noastră de ingineri și specialiști depune eforturi susținute
          pentru a realiza viziunea noastră de a trăi într-o țară unde costul
          energiei este corect iar accesul la aceasta este simplificat.
        </div>
        <div
          role="button"
          className="sliderButton blueButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contact")}
          onTouchStart={() => navigate("/contact")}
        >
          Contactează-ne
        </div>
      </div>
    </div>,
    <div key={3} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">
          Construim rețele pentru distribuția gazelor naturale
        </div>
        <div className="slideSubTitle colorWhite">
          Suntem aici pentru a crește oportunitățile economice ale
          consumatorilor industriali, cât și nivelul de confort a clienților
          casnici. Astfel, dorim să aducem gazele naturale în cât mai multe zone
          ale României.
        </div>
        <div
          role="button"
          className="sliderButton blueButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contact")}
          onTouchStart={() => navigate("/contact")}
        >
          Contactează-ne
        </div>
      </div>
    </div>,
    <div key={4} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">
          Clienții noștri, partenerii noștri
        </div>
        <div className="slideSubTitle colorWhite">
          Suntem alături de clienții noștri industriali și casnici pe toată
          perioada contractuală. Astfel, asigurăm servicii calitative, rapide și
          corecte pentru un parteneriat de lungă durată.
        </div>
        <div
          role="button"
          className="sliderButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contact")}
          onTouchStart={() => navigate("/contact")}
        >
          Contactează-ne
        </div>
      </div>
    </div>,
    <div key={5} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">
          11 localități beneficiază de expertiza noastră
        </div>
        <div className="slideSubTitle colorWhite">
          În anul 2005 am dezvoltat prima noastră rețea de distribuție în
          Miroslava. În prezent, această călătorie a condus la gestionarea unui
          portofoliu de 11 localități, având planuri de a crește acest număr la
          13 localități până în 2025.
        </div>
        <div
          role="button"
          className="sliderButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contact")}
          onTouchStart={() => navigate("/contact")}
        >
          Află mai multe
        </div>
      </div>
    </div>,
    <div key={6} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">Serviciile noastre:</div>
        <div className="slideSubTitle colorWhite">
          <ul>
            <li>Avize de amplasament.</li>
            <li>Proiectare PDSB, PDIB și DTAC.</li>
            <li>Studii de fezabilitate și suport tehnic.</li>
            <li>Lucrări de dezvoltare a rețelelor.</li>
            <li>Mentenanță conducte și instalații.</li>
            <li>Intervenții de urgență deschis 24/7.</li>
          </ul>
        </div>
        <div
          role="button"
          className="sliderButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contact")}
          onTouchStart={() => navigate("/contact")}
        >
          Contactează-ne
        </div>
      </div>
    </div>,
    <div key={7} className="slide">
      <div className="slideContentV2">
        <div className="slideTitle colorWhite">Contul tău Gazmir:</div>
        <div className="slideList colorWhite">
          <div className="slColumn">
            <div>Plăți online</div>
            <div>Sold la zi</div>
            <div>Istoric plăți</div>
          </div>
          <div className="slColumn ">
            <div>Istoric consum</div>
            <div>Autocitire online</div>
            <div>Factură electronică</div>
          </div>
        </div>
        <div
          role="button"
          className="sliderButton buttonHover textButtonHover colorWhite"
          onClick={() => navigate("/contulMeu#newSignUpForm")}
          onTouchStart={() => navigate("/contulMeu#newSignUpForm")}
        >
          Creează-ți cont!
        </div>
      </div>
    </div>,
  ]
}
