// @ts-nocheck
import { Link, navigate } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import CarouselJsV2 from "../components/Carouselv2"
import Footer from "../components/Footer"
import SiteMenu from "../components/siteMenu"
import { getUserInfo } from "../utils/services/keepAuth"

const anunturiItems = [
  {
    id: 2,
    title: "PLATĂ ONLINE",
    description:
      "Oferim plată online pentru servicii, fără deplasare la birouri sau bănci.",
    link: "/contulMeu",
    buttonTitle: "Plată online",
    icon: (
      <svg viewBox="0 0 286 180" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M244.45 157.25H12.62L9.62 156.03L1.27 147.68L0.0500031 144.68V13.18L1.27 10.18L9.62 1.83L12.62 0.610001H244.45L247.4 1.83L255.75 10.18L256.97 13.18V144.72L255.75 147.72L247.4 156L244.45 157.25ZM14.35 148.9H242.72L248.62 142.99V14.9L242.72 9H14.35L8.45 14.9V143L14.35 148.9Z"
          fill="currentColor"
        />
        <path
          d="M252.8 68.92H4.27L0.100006 64.75V38.12L4.27 33.94H252.8L256.97 38.12V64.75L252.8 68.92ZM8.45 60.57H248.62V42.29H8.45V60.57Z"
          fill="currentColor"
        />
        <path
          d="M175.83 66.55V104.84C175.83 134.72 196.31 162.05 228.78 175.49C261.25 162.05 281.78 134.72 281.78 104.84V66.55L228.78 51.43L175.83 66.55Z"
          fill="white"
        />
        <path
          d="M227.18 179.35C192.93 165.16 171.65 136.61 171.65 104.84V66.55L174.65 62.55L227.6 47.44H229.9L282.85 62.55L285.85 66.55V104.84C285.85 136.61 264.58 165.16 230.33 179.35H227.18ZM180 69.7V104.84C180 132.68 198.65 157.84 228.78 170.96C258.91 157.88 277.55 132.68 277.55 104.84V69.7L228.78 55.77L180 69.7Z"
          fill="currentColor"
        />
        <path
          d="M228.78 144.74C221.858 144.74 215.091 142.687 209.335 138.841C203.579 134.996 199.093 129.529 196.444 123.134C193.795 116.738 193.102 109.701 194.452 102.912C195.803 96.1225 199.136 89.8861 204.031 84.9913C208.926 80.0964 215.162 76.763 221.952 75.4125C228.741 74.062 235.778 74.7552 242.174 77.4042C248.569 80.0533 254.036 84.5393 257.881 90.295C261.727 96.0508 263.78 102.818 263.78 109.74C263.78 119.023 260.093 127.925 253.529 134.489C246.965 141.053 238.063 144.74 228.78 144.74ZM228.78 83.13C223.513 83.13 218.364 84.6918 213.985 87.618C209.606 90.5441 206.193 94.7032 204.177 99.5692C202.162 104.435 201.634 109.79 202.662 114.955C203.689 120.121 206.225 124.866 209.95 128.59C213.674 132.315 218.419 134.851 223.585 135.878C228.75 136.906 234.105 136.378 238.971 134.363C243.837 132.347 247.996 128.934 250.922 124.555C253.848 120.176 255.41 115.027 255.41 109.76C255.405 102.699 252.597 95.9285 247.604 90.9356C242.611 85.9426 235.841 83.1353 228.78 83.13Z"
          fill="currentColor"
        />
        <path
          d="M38.25 128.81C34.4799 128.81 30.7944 127.692 27.6598 125.597C24.5252 123.502 22.0822 120.525 20.6399 117.042C19.1976 113.558 18.8207 109.725 19.557 106.028C20.2932 102.33 22.1095 98.9342 24.7761 96.269C27.4427 93.6038 30.8398 91.7893 34.5377 91.055C38.2357 90.3207 42.0683 90.6996 45.5509 92.1437C49.0335 93.5879 52.0095 96.0324 54.1027 99.1681C56.1958 102.304 57.312 105.99 57.31 109.76C57.3021 114.812 55.2911 119.654 51.7181 123.225C48.1451 126.796 43.3017 128.805 38.25 128.81ZM38.25 99.05C36.1335 99.0579 34.0668 99.6927 32.3108 100.874C30.5548 102.056 29.1883 103.731 28.3838 105.689C27.5793 107.647 27.3729 109.799 27.7907 111.874C28.2085 113.949 29.2316 115.853 30.731 117.347C32.2304 118.841 34.1388 119.857 36.2153 120.267C38.2917 120.677 40.4431 120.463 42.3977 119.651C44.3524 118.839 46.0227 117.466 47.1978 115.706C48.3729 113.946 49 111.877 49 109.76C48.9987 108.351 48.7195 106.955 48.1783 105.654C47.6371 104.352 46.8446 103.17 45.8461 102.176C44.8476 101.181 43.6627 100.393 42.3593 99.8564C41.0559 99.3201 39.6595 99.046 38.25 99.05Z"
          fill="currentColor"
        />
        <path
          d="M84.51 128.81C80.7399 128.81 77.0544 127.692 73.9198 125.597C70.7852 123.502 68.3422 120.525 66.8999 117.042C65.4576 113.558 65.0807 109.725 65.817 106.028C66.5532 102.33 68.3695 98.9342 71.0361 96.269C73.7027 93.6038 77.0998 91.7893 80.7977 91.055C84.4957 90.3207 88.3283 90.6996 91.8109 92.1437C95.2935 93.5879 98.2695 96.0324 100.363 99.1681C102.456 102.304 103.572 105.99 103.57 109.76C103.562 114.812 101.551 119.654 97.9781 123.225C94.4051 126.796 89.5617 128.805 84.51 128.81ZM84.51 99.05C82.3918 99.05 80.3211 99.6781 78.5599 100.855C76.7986 102.032 75.4259 103.704 74.6153 105.661C73.8047 107.618 73.5926 109.772 74.0058 111.849C74.419 113.927 75.4391 115.835 76.9369 117.333C78.4347 118.831 80.3431 119.851 82.4206 120.264C84.4981 120.677 86.6516 120.465 88.6086 119.655C90.5655 118.844 92.2382 117.471 93.4151 115.71C94.5919 113.949 95.22 111.878 95.22 109.76C95.2174 106.92 94.0881 104.198 92.0802 102.19C90.0723 100.182 87.3497 99.0526 84.51 99.05Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    id: 4,
    title: "VREI SĂ TE RACORDEZI?",
    description:
      "Află despre procesul de racordare, extindere conductă și punere în funcțiune a instalației.",
    link: "/cerereRacordare/1",
    buttonTitle: "Cerere",
    icon: (
      <svg viewBox="0 0 280 190" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M60.77 189.81H4.90998L0.72998 185.64V170.35L4.90998 166.17H60.77L64.95 170.35V185.64L60.77 189.81ZM9.07998 181.46H56.6V174.52H9.07998V181.46Z"
          fill="currentColor"
        />
        <path
          d="M275.09 189.81H219.23L215.05 185.64V170.35L219.23 166.17H275.09L279.27 170.35V185.64L275.09 189.81ZM223.4 181.46H270.92V174.52H223.4V181.46Z"
          fill="currentColor"
        />
        <path
          d="M259.8 174.52H234.51L230.34 170.35V93.35C230.34 92.2494 229.903 91.1938 229.124 90.4155C228.346 89.6372 227.291 89.2 226.19 89.2H53.8C52.7028 89.2026 51.6513 89.6397 50.8755 90.4155C50.0997 91.1913 49.6626 92.2428 49.66 93.34V170.34L45.48 174.51H20.2L16 170.35V85.06C16.0106 77.25 19.1178 69.7629 24.6403 64.2403C30.1628 58.7178 37.65 55.6106 45.46 55.6H234.46C238.334 55.5947 242.171 56.3526 245.752 57.8302C249.333 59.3079 252.588 61.4764 255.331 64.212C258.074 66.9477 260.252 70.1968 261.739 73.7739C263.227 77.351 263.995 81.186 264 85.06V170.35L259.8 174.52ZM238.69 166.17H255.63V85.06C255.609 79.4707 253.375 74.1172 249.417 70.1705C245.459 66.2238 240.099 64.0052 234.51 64H45.51C39.9129 64.0053 34.5466 66.2311 30.5888 70.1888C26.6311 74.1466 24.4053 79.5129 24.4 85.11V166.22H41.34V93.38C41.3426 90.0683 42.6594 86.8929 45.0012 84.5512C47.3429 82.2094 50.5183 80.8926 53.83 80.89H226.19C229.504 80.8953 232.68 82.2139 235.023 84.557C237.366 86.9001 238.685 90.0764 238.69 93.39V166.17Z"
          fill="currentColor"
        />
        <path d="M92.54 96.53V48.09H77.25V96.53H92.54Z" fill="white" />
        <path
          d="M92.54 100.7H77.25L73.07 96.53V48.09L77.25 43.91H92.54L96.72 48.09V96.53L92.54 100.7ZM81.42 92.35H88.36V52.26H81.42V92.35Z"
          fill="currentColor"
        />
        <path d="M218.05 96.53V48.09H202.76V96.53H218.05Z" fill="white" />
        <path
          d="M218 100.7H202.75L198.57 96.53V48.09L202.75 43.91H218L222.18 48.09V96.53L218 100.7ZM206.88 92.35H213.83V52.26H206.88V92.35Z"
          fill="currentColor"
        />
        <path
          d="M158.45 64H136.84L132.66 59.83V38.17L136.84 33.99H158.45L162.63 38.17V59.78L158.45 64ZM141 55.6H154.26V42.34H141V55.6Z"
          fill="currentColor"
        />
        <path
          d="M153.1 16.29H142.19L138.02 12.12V4.36L142.19 0.190002H153.1L157.27 4.36V12.12L153.1 16.29Z"
          fill="currentColor"
        />
        <path
          d="M158.45 31.07H136.84L132.66 26.9V13.8L136.84 9.62H158.45L162.63 13.8V26.9L158.45 31.07ZM141 22.72H154.26V18H141V22.72Z"
          fill="currentColor"
        />
        <path
          d="M180.06 31.07H158.45L154.27 26.9V13.8L158.45 9.62H180.06L184.24 13.8V26.9L180.06 31.07ZM162.63 22.72H175.88V18H162.63V22.72Z"
          fill="currentColor"
        />
        <path
          d="M136.84 31.07H115.23L111.05 26.9V13.8L115.23 9.62H136.84L141 13.8V26.9L136.84 31.07ZM119.4 22.72H132.66V18H119.4V22.72Z"
          fill="currentColor"
        />
        <path d="M151.82 26.9H143.47V38.17H151.82V26.9Z" fill="currentColor" />
      </svg>
    ),
  },
  {
    id: 1,
    title: "AVIZE DE AMPLASAMENT",
    description:
      "Cunoașterea localizării rețelei de gaz față de proprietatea ta este esențială pentru planificarea modernizărilor sau construcțiilor noi.",
    link: "/cereri/avizAmplasament",
    buttonTitle: "Cerere",
    icon: (
      <svg viewBox="0 0 234 294" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M229.16 293.91H4.84L0.669998 289.74V87.74L1.89 84.74L85.41 1.23001L88.41 0.0100098H229.21L233.38 4.18001V289.74L229.16 293.91ZM9 285.56H225V8.44001H90.09L9 89.5V285.56Z"
          fill="currentColor"
        />
        <path
          d="M88.36 92H4.84L1.84 84.87L85.36 1.35999L92.48 4.35999V87.87L88.36 92ZM14.93 83.65H84.18V14.34L14.93 83.65Z"
          fill="currentColor"
        />
        <path d="M185.92 111.4H46.6V119.75H185.92V111.4Z" fill="currentColor" />
        <path
          d="M158.08 138.78H46.6V147.13H158.08V138.78Z"
          fill="currentColor"
        />
        <path
          d="M185.92 166.16H46.6V174.51H185.92V166.16Z"
          fill="currentColor"
        />
        <path
          d="M130.24 193.54H46.6V201.89H130.24V193.54Z"
          fill="currentColor"
        />
        <path
          d="M170.54 220.92H46.6V229.27H170.54V220.92Z"
          fill="currentColor"
        />
        <path d="M185.92 248.3H46.6V256.65H185.92V248.3Z" fill="currentColor" />
      </svg>
    ),
  },

  {
    id: 3,
    title: "REVIZII ȘI VERIFICĂRI",
    description:
      "Pentru că siguranța ta primează, utilizarea și intreținerea corectă a instalației de utilizare este la fel de importanta cum este si programarea la timp a verificărilor și reviziilor tehnice.",
    link: "/contact",
    buttonTitle: "Contact",
    icon: (
      <svg viewBox="0 0 244 244" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M41.7 243.14H35.79L0.869995 208.21V202.3L128.35 74.82C122.48 55.01 128.28 33.42 143.8 17.9C151.352 10.3289 160.85 4.99295 171.244 2.4817C181.638 -0.0295442 192.525 0.381707 202.7 3.67001L204.38 10.6L169.83 45.14L198.83 74.14L233.38 39.59L240.3 41.27C243.588 51.4453 244 62.3317 241.488 72.7261C238.977 83.1205 233.641 92.6183 226.07 100.17C210.55 115.69 188.96 121.48 169.15 115.63L41.7 243.14ZM9.7 205.25L38.7 234.25L165.08 108L169.41 107C186.83 113.08 206.3 108.23 220.19 94.34C225.821 88.7267 230.022 81.8426 232.437 74.2672C234.853 66.6918 235.413 58.647 234.07 50.81L201.81 83H195.91L161 48.1V42.19L193.25 9.93001C185.413 8.5866 177.368 9.14625 169.792 11.5618C162.217 13.9773 155.333 18.178 149.72 23.81C135.81 37.7 131 57.16 137 74.6L136 78.92L9.7 205.25Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
]
export default function Home({ location }) {
  React.useEffect(() => {
    //Redirect logged in user to dashboard
    if (getUserInfo() != null && location.state == null) {
      navigate("/app/home")
    }
  }, [location])

  return (
    <>
      <div className="homeV2">
        <Helmet>
          <title>Gazmir.ro - Furnizor gaze naturale</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
          <meta
            name="description"
            content="Gazmir - furnizorul tau de gaze naturale. Beneficiaza de sprijin la
              plata facturilor, semneaza contractul de furnizare! Achita facturi
              online, vezi consumul"
          />
          <meta
            name="keywords"
            content="Gazmir,Furnizor gaz,Furnizare gaz,Furnizare gaze naturale,gaze
              naturale,gaz,gn,gaz miroslava,gaz panciu,gaz odobesti,gaz
              chirnogi,Gazmir miroslava,Gazmir panciu,Gazmir odobesti,Gazmir
              chirnogi,Gazmir.ro,Contract gaz,Pret gaz,Gaz Vrancea,Gaz Iasi"
          />
        </Helmet>
        <SiteMenu location={location} />
        <div id="h-firstSection">
          <div className="caruselContainerV2">
            {/* <Carousel /> */}
            <CarouselJsV2 />
            {/* <SimplyCarousel /> */}
          </div>
        </div>
        <div className="h2AnunturiSection">
          {anunturiItems.map(item => (
            <div className="h2AnunturiItem" key={item.id}>
              <div className="textBox">
                <div className="iconContainer">{item.icon}</div>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <Link className="buttonHover" to={item.link}>
                {item.buttonTitle}
              </Link>
            </div>
          ))}
        </div>
      </div>

      <Footer containerClassName="homeFooter" />
      {/* <CookieBanner /> */}
    </>
  )
}
