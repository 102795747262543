export default function Carousel(element, callbackFn) {
  this._autoDuration = 0
  this._container = element.querySelector("#carousellHome")
  this._interval = null
  this._timeout = null
  this._nav = element.querySelector("nav")
  this._slide = 0
  this._touchAnchorX = 0
  this._touchAnchorY = 0
  this._touchTime = 0
  this._touchX1 = 0
  this._touchX2 = 0
  this._callbackFn = callbackFn
  element.addEventListener("click", this)
  element.addEventListener("touchstart", this)
  element.addEventListener("touchmove", this)
  element.addEventListener("touchend", this)
  element.addEventListener("transitionend", this)
  window.addEventListener("blur", this)
  window.addEventListener("focus", this)
  this.set(0)
}

Carousel.prototype.auto = function (ms) {
  // if (this._interval) {
  //   clearInterval(this._interval)
  //   this._interval = null
  // }
  // if (ms) {
  //   this._autoDuration = ms
  //   var self = this
  //   this._interval = setInterval(function () {
  //     self.next()
  //   }, ms)
  // }
  if (this._timeout) {
    clearTimeout(this._timeout)
    this._timeout = null
  }
  if (ms) {
    // console.log("Auto called: ", this._slide)
    this._autoDuration = ms
    var self = this
    this._timeout = setTimeout(function () {
      // console.log("Auto timeout: ", self._slide)
      self.next()
    }, ms)
  }
}

Carousel.prototype.handleEvent = function (event) {
  if (event.touches && event.touches.length > 0) {
    this._touchTime = +new Date()
    this._touchX1 = this._touchX2
    this._touchX2 = event.touches[0].screenX
  }

  if (event.target && event.target.tagName == "A" && event.type == "touchend") {
    // console.log(event.type)
    // console.log(event.target)
    const dataSlide = parseInt(event.target.getAttribute("data-slide"))
    if (!isNaN(dataSlide)) {
      // console.log(dataSlide, typeof dataSlide)
      this.set(dataSlide)
    }
    // if (event.target.getAttribute("data-slide") == null)
    // this.set(event.target.getAttribute("data-slide"))
    // console.log(
    //   "Data-slide attribute: ",
    //   event.target.getAttribute("data-slide")
    // )
  }

  var screen = document.documentElement.clientWidth
  var position = this._slide + (this._touchAnchorX - this._touchX2) / screen
  var velocity =
    new Date() - this._touchTime <= 200
      ? (this._touchX1 - this._touchX2) / screen
      : 0

  switch (event.type) {
    case "blur":
      this.auto(0)
      break
    case "click":
      if (event.target.parentNode != this._nav) break
      var i = parseInt(event.target.dataset.slide)
      if (!isNaN(i)) {
        event.preventDefault()
        this.auto(0)
        this.set(i)
      }
      break
    case "focus":
      this.auto(this._autoDuration)
      break
    case "touchstart":
      this.auto(0)
      this._touchAnchorX = this._touchX1 = this._touchX2
      this._touchAnchorY = event.touches[0].screenY // Track Y position to determine scroll direction
      this._container.style.transition = "none"
      break
    case "touchmove":
      let deltaX = this._touchX2 - this._touchAnchorX
      let deltaY = Math.abs(event.touches[0].screenY - this._touchAnchorY)

      // Only prevent default if horizontal movement is greater than a certain threshold
      // and greater than the vertical movement (to allow for natural scrolling)
      if (Math.abs(deltaX) > Math.min(40, deltaY)) {
        event.preventDefault()
        // var screen = document.documentElement.clientWidth
        // var position = this._slide - deltaX / screen
        this._container.style.transform = `translate3d(${
          -position * this._container.offsetWidth
        }px, 0, 0)`
      }
      break
    // case "touchstart":
    //   // event.preventDefault()
    //   this.auto(0)
    //   this._container.style.transition = "none"
    //   this._touchAnchorX = this._touchX1 = this._touchX2
    //   break
    // case "touchmove":
    //   this._container.style.transform =
    //     "translate3d(" + -position * this._container.offsetWidth + "px, 0, 0)"
    //   break
    case "touchend":
      this._container.style.transition = ""
      var offset = Math.min(Math.max(velocity * 4, -0.5), 0.5)
      this.set(Math.round(position + offset))
      break
    case "transitionend":
      // console.log("Transition end event")
      var i = this._slide,
        count = this._countSlides()
      if (i >= 0 && i < count) break
      // The slides should wrap around. Instantly move to just outside screen on the other end.
      this._container.style.transition = "none"
      this._container.style.transform =
        "translate3d(" +
        (i < 0
          ? -count * this._container.offsetWidth
          : this._container.offsetWidth) +
        "px, 0, 0)"
      // Force changes to be applied sequentially by reflowing the element.
      this._container.offsetHeight
      setTimeout(() => {
        this._container.style.transition = ""
      }, 1)
      // this._container.style.transition = ""
      // this._container.offsetHeight
      // Animate the first/last slide in.
      this.set(i < 0 ? count - 1 : 0)
      break
  }
}

Carousel.prototype.next = function () {
  this.set(this._slide + 1)
}

Carousel.prototype.previous = function () {
  this.set(this._slide - 1)
}

Carousel.prototype.set = function (i) {
  this._callbackFn(i)
  var count = this._countSlides()
  if (i < 0) {
    i = -1
  } else if (i >= count) {
    i = count
  }
  this._slide = i
  this._container.style.transform =
    "translate3d(" + -i * this._container.offsetWidth + "px, 0, 0)"

  this._updateNav()
  this.auto(this._autoDuration)
}

Carousel.prototype._countSlides = function () {
  // return this._container.querySelectorAll(".slide").length
  return 7
}

Carousel.prototype._updateNav = function () {
  var html = "",
    count = this._countSlides()
  for (var i = 0; i < count; i++) {
    // if (i > 0) html += "&nbsp;"
    html +=
      "<a" +
      (i == this._slide ? ' class="current"' : "") +
      ' data-slide="' +
      i +
      '" ></a>'
  }
  this._nav.innerHTML = html
  // this._nav.onTouchEnd = event => {
  //   console.log(event)
  //   console.log(event.target)
  //   console.log(event.target.getAttribute("data-slide"))
  // }
}
