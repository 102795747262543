// @ts-nocheck
import { navigate } from "gatsby-link"
import React from "react"
import "./index.css"
import Carousel from "./carouselLib"
import Slides from "./Slides"

const arrowRightSvg = (
  <svg
    width="22"
    height="82"
    viewBox="0 0 22 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 81L20 41L1.00001 0.999998"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default function CarouselJsV2({}) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const carouselRef = React.useRef()
  React.useEffect(() => {
    var carousels = Array.prototype.map.call(
      document.querySelectorAll(".carouselv2"),
      function (element) {
        carouselRef.current = new Carousel(element, slideChanged)
        carouselRef.current.auto(5000)
        return carouselRef.current
      }
    )
    return () => {
      if (carouselRef.current && carouselRef.current._timeout) {
        clearInterval(carouselRef.current._timeout)
        carouselRef.current._timeout = null
      }
    }
  }, [])

  React.useEffect(() => {
    if (carouselRef.current != null) {
      console.log(carouselRef.current._slide)
    }
  }, [carouselRef.current?._slide])

  const slideChanged = slide => {
    // console.log(slide)
    // setCurrentSlide(slide)
    // console.log(document.querySelectorAll(".slide")[slide])
    const currentSlide = document.querySelectorAll(".slide")[slide]
    Array.prototype.forEach.call(
      document.querySelectorAll(".slide"),
      function (element) {
        element.setAttribute("data-state", "")
      }
    )
    //get next slide
    if (slide < 7) {
      const nextSlide = document.querySelectorAll(".slide")[(slide + 1) % 8]
      if (nextSlide != null) {
        nextSlide.setAttribute("data-state", "next")
      }
    }
    // setCurrentSlide(slide)
  }

  const arrowLeftPressed = event => {
    event.preventDefault()
    carouselRef.current.previous()
  }

  const arrowRightPressed = event => {
    event.preventDefault()
    // console.log("right")
    carouselRef.current.next()
  }

  return (
    <section className="carouselv2 carouselGradient">
      <div className="leftBlurBox">
        <div
          className="sliderLeftArrow"
          onClick={arrowLeftPressed}
          onTouchEnd={arrowLeftPressed}
        >
          {arrowRightSvg}
        </div>
      </div>
      <nav></nav>
      <div className="rightBlurBox">
        <div
          className="sliderRightArrow"
          onClick={arrowRightPressed}
          onTouchEnd={arrowRightPressed}
        >
          {arrowRightSvg}
        </div>
      </div>
      <div id="carousellHome">
        <Slides currentSlide={currentSlide} />
      </div>
    </section>
  )
}
